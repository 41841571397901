// import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import {
  CarTwoTone,
  DashboardOutlined,
  ProfileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import logo from "../../assets/images/logo.png";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
        <span>React Dashboard</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              <DashboardOutlined />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/cars">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              <CarTwoTone />
            </span>
            <span className="label">Cars</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/drivers">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              <UserOutlined />
            </span>
            <span className="label">Drivers</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/conductors">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              <UserOutlined />
            </span>
            <span className="label">Conductors</span>
          </NavLink>
        </Menu.Item>

        <Menu.Item className="menu-item-header" key="5">
          Account Pages
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              <ProfileOutlined />
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item>
      </Menu>
      <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            <DashboardOutlined />
          </span>
          <h6>Need Help?</h6>
          <p>Please check our docs</p>
          <Button type="primary" className="ant-btn-sm ant-btn-block">
            DOCUMENTATION
          </Button>
        </div>
      </div>
    </>
  );
}

export default Sidenav;
