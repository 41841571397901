import ky from "ky";

export const baseUrl = process.env.REACT_APP_API_URL;
export const api = `${baseUrl}/admin`;
const Api = {
  baseUrl,
  image: (id) => `${baseUrl}/${id}`,
  auth: {
    login: `${api}/login`,
  },
  lists: `${api}/dashboard/lists`,
  cars: {
    list: `${api}/cars/index?cars`,
    add: `${api}/cars/add`,
  },
  drivers: {
    list: `${api}/drivers/index?drivers`,
    add: `${api}/drivers/add`,
  },
  conductors: {
    list: `${api}/conductors/index?conductors`,
    add: `${api}/conductors/add`,
  },
  routes: {
    list: `${api}/routes/index?routes`,
    values: `${api}/routes/lists`,
    add: `${api}/routes/add`,
  },
};
export default Api; //

export const KY = ky.create({
  hooks: {
    beforeRequest: [
      (req) => {
        req.headers.set("Authorization", sessionStorage.getItem("authKey"));
      },
    ],
  },
});
