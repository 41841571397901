import { useState } from "react";
import Api, { KY } from "src/Api";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Select, Form, Input, Button, Alert } from "antd";
import { errorExtract } from "src/Functions";
import TextArea from "antd/lib/input/TextArea";

function Tables() {
  let history = useHistory();
  const [errors, setErrors] = useState([]);
  const onFinish = async (values) => {
    setErrors([]);
    const resp = await KY.post(Api.conductors.add, { json: values }).json();
    if (resp?.status) history.replace("/conductors");
    else setErrors(errorExtract(resp?.errors ?? {}));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card bordered={false} title="Conductors Add">
              {errors.map((item, key) => (
                <Alert
                  key={"Conductors_error_key" + key}
                  message={item}
                  type="error"
                  style={{ marginBottom: 5 }}
                />
              ))}

              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="horizontal"
                className="row-col"
              >
                <Row justify="space-between">
                  <Col span={8}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input Name",
                        },
                      ]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                  </Col>
                  <Col span="6">
                    <Form.Item
                      label="Phone"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input Phone!",
                        },
                      ]}
                    >
                      <Input placeholder="Phone" type="tel" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Image"
                      name="image"
                      rules={[
                        {
                          message: "Please input Image",
                        },
                      ]}
                    >
                      <Input placeholder="Image" type="file" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span={8}>
                    <Form.Item
                      label="License Number"
                      name="d_l_no"
                      rules={[
                        {
                          // required: true,
                          message: "Please input License Number",
                        },
                      ]}
                    >
                      <Input placeholder="License Number" disabled />
                    </Form.Item>
                  </Col>
                  <Col span="6">
                    <Form.Item
                      label="Alternate No"
                      name="alternate_no"
                      rules={[
                        {
                          // required: true,
                          message: "Please input Alternate number",
                        },
                      ]}
                    >
                      <Input placeholder="Alternate number" type="tel" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="License Image"
                      name="d_l_image"
                      rules={[
                        {
                          message: "Please input Image",
                        },
                      ]}
                    >
                      <Input placeholder="Image" type="file" disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span="8">
                    <Form.Item
                      label="ID Type"
                      name="id_card_type"
                      rules={[
                        {
                          required: true,
                          message: "Please select Id Type",
                        },
                      ]}
                    >
                      <Select
                        fullWidth
                        showSearch
                        style={{
                          width: 200,
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        options={[
                          { label: "Aadhar Id", value: "AadharId" },
                          { label: "Voter Id", value: "VoterId" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="ID Number"
                      name="id_number"
                      rules={[
                        {
                          required: true,
                          message: "Please input ID Number",
                        },
                      ]}
                    >
                      <Input placeholder="Image" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="ID Image"
                      name="id_image"
                      rules={[
                        {
                          message: "Please input Image",
                        },
                      ]}
                    >
                      <Input placeholder="Image" type="file" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span="24">
                    <Form.Item
                      label="Address"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please select Address",
                        },
                      ]}
                    >
                      <TextArea></TextArea>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span="2">
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
