import { useState } from "react";
import Api, { KY } from "src/Api";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Select, Form, Input, Button, Alert } from "antd";
import { errorExtract } from "src/Functions";

function Tables() {
  let history = useHistory();
  const { data } = useQuery(
    "carAddData",
    async () => await KY.get(Api.routes.values).json()
  );
  const [errors, setErrors] = useState([]);
  const onFinish = async (values) => {
    setErrors([]);
    const resp = await KY.post(Api.routes.add, { json: values }).json();
    if (resp?.status) history.replace("/routes");
    else setErrors(errorExtract(resp?.errors ?? {}));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card bordered={false} title="Routes Add">
              {errors.map((item, key) => (
                <Alert
                  key={"Routes_error_key" + key}
                  message={item}
                  type="error"
                  style={{ marginBottom: 5 }}
                />
              ))}

              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="horizontal"
                className="row-col"
              >
                <Row justify="space-between">
                  <Col span={10}>
                    <Form.Item
                      label="Start Point"
                      name="start_point"
                      rules={[
                        {
                          required: true,
                          message: "Please input Start Point",
                        },
                      ]}
                    >
                      <Input placeholder="Start Point" />
                    </Form.Item>
                  </Col>
                  <Col span="10">
                    <Form.Item
                      label="Start Location"
                      name="start_location"
                      rules={[
                        {
                          required: true,
                          message: "Please input Start Location!",
                        },
                      ]}
                    >
                      <Input placeholder="Start Location" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span={10}>
                    <Form.Item
                      label="End Point"
                      name="end_point"
                      rules={[
                        {
                          required: true,
                          message: "Please input End Point",
                        },
                      ]}
                    >
                      <Input placeholder="End Point" />
                    </Form.Item>
                  </Col>
                  <Col span="10">
                    <Form.Item
                      label="End Location"
                      name="end_location"
                      rules={[
                        {
                          required: true,
                          message: "Please input End Location!",
                        },
                      ]}
                    >
                      <Input placeholder="End Location" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span={10}>
                    <Form.Item
                      label="Distance"
                      name="distance"
                      rules={[
                        {
                          required: true,
                          message: "Please input Distance",
                        },
                      ]}
                    >
                      <Input placeholder="Distance" type="number" min={1} />
                    </Form.Item>
                  </Col>
                  <Col span="10">
                    <Form.Item
                      label="Car"
                      name="car_id"
                      rules={[
                        {
                          required: true,
                          message: "Please select Car!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        options={data?.cars ?? []}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span={10}>
                    <Form.Item
                      label="Driver"
                      name="driver_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input Driver",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        options={data?.drivers ?? []}
                      />
                    </Form.Item>
                  </Col>
                  <Col span="10">
                    <Form.Item
                      label="Conductor"
                      name="conductor_id"
                      rules={[
                        {
                          required: true,
                          message: "Please select Conductor!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        options={data?.conductors ?? []}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span="2">
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
