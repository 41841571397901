import React, { useState } from "react";
import Ky from "ky";
import { useHistory } from "react-router-dom";
import { Alert, Layout, Button, Row, Col, Typography, Form, Input } from "antd";
import signInBg from "../assets/images/driver/bus.png";
import Api from "src/Api";
import { useSetRecoilState } from "recoil";
import MyRecoil from "src/MyRecoil";
const { Title } = Typography;
const { Footer, Content } = Layout;

export default function SignIn() {
  let history = useHistory();
  const [error, setError] = useState(null);
  const setUser = useSetRecoilState(MyRecoil.bearerToken);
  const onFinish = async (values) => {
    setError(null);
    const resp = await Ky.post(Api.auth.login, { json: values }).json();
    console.log(resp);
    if (resp?.status) {
      setUser(resp?.user?.Auth);
      sessionStorage.setItem("authKey", resp?.user?.Auth);
      history.push("/dashboard");
    } else {
      setError(resp.message ?? "Something went wrong");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Layout className="layout-default layout-signin">
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Sign In</Title>

              <Title className="font-regular text-muted" level={5}>
                Enter your username and password to sign in
              </Title>
              {error && <Alert message={error} type="error" />}
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>
                <Form.Item
                  className="password"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input placeholder="Password" type="password" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img src={signInBg} alt="" />
            </Col>
          </Row>
        </Content>
        <Footer>
          <p className="copyright">Copyright © 2021</p>
        </Footer>
      </Layout>
    </>
  );
}
