import { Row, Col, Card, Button } from "antd";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import Api, { KY } from "src/Api";
import { Table, Tag } from "antd";
import { useMemo } from "react";

function Tables() {
  const history = useHistory();
  const { data } = useQuery(
    "drivers-lists",
    async () => await KY.get(Api.routes.list).json()
  );
  const columns = useMemo(
    () => [
      {
        title: "Start ",
        dataIndex: "start_point",
        key: "name",
        // render: (text) => <a>{text}</a>,
      },
      {
        title: "End",
        dataIndex: "end_point",
        key: "phone",
      },
      {
        title: "Distance",
        dataIndex: "distance",
        key: "distance",
      },
      {
        title: "Capacity",
        dataIndex: "capacity",
        key: "capacity",
        render: (_, { car: { capacity }, total_users }) => {
          return (
            <>
              {total_users}/{capacity}
            </>
          );
        },
      },
      {
        title: "Car No",
        dataIndex: "car_no",
        key: "car_no",
        render: (_, { car: { car_no } }) => {
          return <>{car_no}</>;
        },
      },
      {
        title: "Driver",
        dataIndex: "driver_name",
        key: "driver_name",
        render: (_, { driver: { name } }) => {
          return <>{name}</>;
        },
      },
      {
        title: "Conductor",
        dataIndex: "conductor",
        key: "conductor",
        render: (_, { conductor: { name } }) => {
          return <>{name}</>;
        },
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (_, { status, id }) => {
          const color = {
            Pending: "volcano",
            Active: "Green",
            Deactivated: "red",
          }[status];
          return (
            <>
              <Tag color={color} key={`key_driver_${id}`}>
                {status?.toUpperCase()}
              </Tag>
            </>
          );
        },
      },
      {
        title: "Action",
        key: "action",
        // render: (_, record) => (
        //   <Space size="middle">
        //     <a>Invite {record.name}</a>
        //     <a>Delete</a>
        //   </Space>
        // ),
      },
    ],
    []
  );
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Drivers"
              extra={
                <>
                  <Button
                    type="primary"
                    onClick={() => history.push("/routes-add")}
                  >
                    Add
                  </Button>
                </>
              }
            >
              <Table columns={columns} dataSource={data?.data ?? []} />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
