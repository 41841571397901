import { Row, Col, Card, Button } from "antd";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import Api, { KY } from "src/Api";
import { Table } from "antd";
import { useMemo } from "react";

function Tables() {
  const history = useHistory();
  const { data } = useQuery(
    "car-lists",
    async () => await KY.get(Api.cars.list).json()
  );
  const columns = useMemo(
    () => [
      {
        title: "Car No",
        dataIndex: "car_no",
        key: "car_no",
        // render: (text) => <a>{text}</a>,
      },
      {
        title: "Color",
        dataIndex: "color",
        key: "color",
      },
      {
        title: "Capacity",
        dataIndex: "capacity",
        key: "capacity",
      },
      {
        title: "Brand",
        key: "company_name",
        dataIndex: "company_name",
        // render: (_, { tags }) => (
        //   <>
        //     {tags.map((tag) => {
        //       let color = tag.length > 5 ? "geekblue" : "green";
        //       if (tag === "loser") {
        //         color = "volcano";
        //       }
        //       return (
        //         <Tag color={color} key={tag}>
        //           {tag.toUpperCase()}
        //         </Tag>
        //       );
        //     })}
        //   </>
        // ),
      },
      {
        title: "Action",
        key: "action",
        // render: (_, record) => (
        //   <Space size="middle">
        //     <a>Invite {record.name}</a>
        //     <a>Delete</a>
        //   </Space>
        // ),
      },
    ],
    []
  );
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Cars"
              extra={
                <>
                  <Button
                    type="primary"
                    onClick={() => history.push("/cars-add")}
                  >
                    Add
                  </Button>
                </>
              }
            >
              {/* <div id="cars-table"></div> */}
              <Table columns={columns} dataSource={data?.data ?? []} />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
