import { Switch, Route, Redirect } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import MyRecoil from "src/MyRecoil";
import Home from "./pages/Home";

// Cars
import Cars from "./pages/Cars/Index";
import CarsAdd from "./pages/Cars/Add";

//Drivers
import Drivers from "./pages/Drivers/Index";
import DriversAdd from "./pages/Drivers/Add";

//Conductors
import Conductors from "./pages/Conductors/Index";
import ConductorsAdd from "./pages/Conductors/Add";

//Conductors
import Routes from "./pages/Routes/Index";
import RoutesAdd from "./pages/Routes/Add";

import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { useEffect } from "react";
import Api, { KY } from "./Api";
import { useState } from "react";

function App() {
  const setUser = useSetRecoilState(MyRecoil.bearerToken);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function checkLogin() {
      const resp = await KY.post(Api.auth.login, { json: {} }).json();
      if (resp?.status) {
        setUser(resp?.user?.Auth);
        sessionStorage.setItem("authKey", resp?.user?.Auth);
        // history.push("/dashboard");
      }
      setLoading(false);
    }
    if (sessionStorage.getItem("authKey")) {
      checkLogin();
    } else {
      setLoading(false);
    }
  }, [setUser]);
  const loginStatus = !!useRecoilValue(MyRecoil.bearerToken);
  const path = loginStatus ? "/dashboard" : "/sign-in";
  return (
    <div className="App">
      {!loading && (
        <Switch>
          <Route path="/sign-in" exact component={SignIn} />
          {loginStatus && (
            <Main>
              <Route exact path="/dashboard" component={Home} />
              <Route exact path="/cars" component={Cars} />
              <Route exact path="/cars-add" component={CarsAdd} />
              <Route exact path="/drivers" component={Drivers} />
              <Route exact path="/drivers-add" component={DriversAdd} />
              <Route exact path="/conductors" component={Conductors} />
              <Route exact path="/conductors-add" component={ConductorsAdd} />
              <Route exact path="/routes" component={Routes} />
              <Route exact path="/routes-add" component={RoutesAdd} />
              <Route exact path="/profile" component={Profile} />
            </Main>
          )}
          <Redirect from="*" to={path} />
        </Switch>
      )}
    </div>
  );
}

export default App;
