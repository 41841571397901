import { atom } from "recoil";

export const projectName = atom({
  key: "projectName",
  default: "MIS",
});

export const userDetails = atom({
  key: "userDetails",
  default: null,
});

export const bearerToken = atom({
  key: "bearerToken",
  default: null,
});

const MyRecoil = {
  projectName,
  userDetails,
  bearerToken,
};
export default MyRecoil;
