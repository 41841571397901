import { useState } from "react";
import Api, { KY } from "src/Api";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Select, Form, Input, Button, Alert } from "antd";
import { errorExtract } from "src/Functions";

function Tables() {
  let history = useHistory();
  const { data } = useQuery(
    "carAddData",
    async () => await KY.get(Api.lists).json()
  );
  const [errors, setErrors] = useState([]);
  const onFinish = async (values) => {
    setErrors([]);
    const resp = await KY.post(Api.cars.add, { json: values }).json();
    if (resp?.status) history.replace("/cars");
    else setErrors(errorExtract(resp?.errors ?? {}));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card bordered={false} title="Cars Add">
              {errors.map((item, key) => (
                <Alert
                  key={"cars_error_key" + key}
                  message={item}
                  type="error"
                  style={{ marginBottom: 5 }}
                />
              ))}

              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="horizontal"
                className="row-col"
              >
                <Row justify="space-between">
                  <Col span={6}>
                    <Form.Item
                      label="Car No"
                      name="car_no"
                      rules={[
                        {
                          required: true,
                          message: "Please input car no",
                        },
                      ]}
                    >
                      <Input placeholder="Car No" />
                    </Form.Item>
                  </Col>
                  <Col span="6">
                    <Form.Item
                      label="Color"
                      name="color"
                      rules={[
                        {
                          required: true,
                          message: "Please input color!",
                        },
                      ]}
                    >
                      <Input placeholder="Color" />
                    </Form.Item>
                  </Col>
                  <Col span="6">
                    <Form.Item
                      label="Type"
                      name="type_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input Type!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{
                          width: 200,
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        options={data?.types ?? []}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span={6}>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please input Description",
                        },
                      ]}
                    >
                      <Input placeholder="Description" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Capacity"
                      name="capacity"
                      rules={[
                        {
                          required: true,
                          message: "Please input capacity",
                        },
                      ]}
                    >
                      <Input placeholder="Capacity" type="number" />
                    </Form.Item>
                  </Col>
                  <Col span="6">
                    <Form.Item
                      label="Brand"
                      name="company_id"
                      rules={[
                        {
                          required: true,
                          message: "Please select Brand!",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{
                          width: 200,
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        options={data?.brands ?? []}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span="2">
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
